<template>
  <div>
    <OrderDialog
      :orderDialogIncome.sync="orderDialog"
      :isComposite="$route.path === '/composite'"
      @closeOrderDialog="closeOrderDialog"
    />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showEmailDialogIncome.show"
      width="90%"
      :before-close="closeDialog"
      append-to-body
      class="common-dialog"
    >
      <el-row
        type="flex"
        justify="center"
        :gutter="20"
        class="xs-flex-direction-column"
      >
        <div class="text-center">{{ showEmailDialogIncome.message }}</div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <div v-if="isOrderBlock" class="text-align-center">
          <h3 class="title-dialog-ad-info">
            {{ sendApplyForChartOfInformation }}
          </h3>
          <el-button
            @click="
              closeDialog();
              scrollToInfoBlock();
            "
            type="primary2"
            class="min-width-125 margin-right-5"
          >
            View content
          </el-button>
          <el-button
            @click="openOrderDialog"
            class="min-width-125 margin-left-5"
            type="green"
          >
            Make order
          </el-button>
        </div>
        <div v-else class="text-align-center">
          <el-button @click="closeDialog" class="min-width-95">Ok</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import OrderDialog from "@/components/dialogs/OrderDialog";

export default {
  name: "EmailConfirmedDialog",
  props: {
    showEmailDialogIncome: {
      type: Object,
      default: function() {
        return { show: false };
      }
    },
    isOrderBlock: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrderDialog
  },
  data() {
    return {
      dialogTitle: "Email confirmation",
      orderDialog: false,
      sendApplyForChartOfInformation:
        "You can order a complete decoding your chart in one document here"
    };
  },
  mixins: [commonReportingMethods],
  methods: {
    openOrderDialog() {
      this.orderDialog = true;
    },
    closeOrderDialog() {
      this.orderDialog = false;
    },
    closeDialog() {
      this.$emit("closeEmailD");
    }
  }
};
</script>
