<template>
  <div>
    <InfoDialog
      :infoDialogIncome.sync="infoDialog"
      :messageIncome="messageIncome"
      @closeInfoDialog="closeInfoDialog"
    />
    <OrderDialog
      :orderDialogIncome.sync="orderDialog"
      @closeOrderDialog="closeOrderDialog"
    />
    <AfterSuccessRegDialog
      :afterSuccessRegDialog.sync="showSuccessDialog"
      @closeSucRegDialog="closeSuccessRegDialog"
    />
    <LoginDialog
      :showDialogIncome.sync="showDialog"
      :email.sync="email"
      :activeNameTab.sync="activeNameTab"
      :isOrderBlock="true"
      @openRemind="showRemindDialog = true"
      @openOrderDialogEvent="orderDialog = true"
      @close="closeDialog"
    />
    <RemindDialog
      :showRemindDialogIncome.sync="showRemindDialog"
      :email.sync="email"
      @close="closeRemindDialog"
    />
    <TooltipUnderConstructionDialog
      :showTooltipUnderConstructionDialogIncome.sync="
        showTooltipUnderConstructionDialog
      "
      @closeTooltipUnderDialog="closeTooltipUnderDialog"
    />
    <TooltipDialog
      :showTooltipDialogIncome.sync="showTooltipDialog"
      :isOrderBlock="true"
      @closeToolDialog="closeTooltipDialog"
    />
    <EmailConfirmedDialog
      :showEmailDialogIncome.sync="showEmailDialog"
      :isOrderBlock="true"
      @closeEmailD="closeEmailDialog"
    />
    <ErrorDialog
      :showErrorDialogIncome.sync="showErrorDialog"
      @closeErrorD="closeErrorDialog"
    />
    <el-row v-if="!bodyGraph" type="flex" justify="center" :gutter="20">
      <el-col :span="12" :xs="24" :xl="12" class="flex-center">
        <ReportForm
          @mathingGatesCentersAndDownloadReport="
            mathingGatesCentersAndDownloadReport
          "
        />
      </el-col>
    </el-row>
    <el-row v-else type="flex" justify="center" :gutter="20">
      <el-col :xs="24" :xl="24" class="report">
        <el-card class="box-card el-card-with-bg">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <div class="no-mobile">
                  Your report: {{ bodyGraph.first_name }},
                  {{ bodyGraph.birthday }}
                </div>
                <div class="no-desktop">
                  Your report:<br />
                  {{ bodyGraph.first_name }}<br />
                  {{ bodyGraph.birthday }}
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-if="bodyGraph.body_graph.type">
            <div class="body-graph-wrapper">
              <BodyGraph
                :bodyGraph.sync="bodyGraph"
                @incomeToolObj="incomeTooltipsObj"
              />
            </div>
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <div class="no-mobile">
                  <el-button
                    v-if="bodyGraph"
                    @click="newReport"
                    type="primary"
                    icon="el-icon-plus"
                    size="medium"
                    plain
                    round
                  >
                    <span>New chart</span>
                  </el-button>
                  <!-- <el-button
                    v-if="bodyGraph.is_stored && isLogged"
                    @click="deleteReport(bodyGraph.id)"
                    icon="el-icon-document-remove"
                    type="danger"
                    size="medium"
                    plain
                    round
                  >
                    <span>Delete report</span>
                  </el-button>
                  <el-button
                    v-if="!bodyGraph.is_stored"
                    @click="saveReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="success"
                    size="medium"
                    plain
                    round
                  >
                    <span>Save report</span>
                  </el-button>
                  <el-button
                    @click="shareReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="medium"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button> -->
                  <el-link
                    v-if="isLogged"
                    download="report.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-link
                    v-else
                    @click="showDialog = true"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-button
                    v-if="!isLogged"
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="medium"
                    plain
                    round
                  >
                    <span>Login/Register</span>
                  </el-button>
                </div>
                <div class="no-desktop">
                  <!-- <el-button
                    v-if="bodyGraph.is_stored && isLogged"
                    @click="deleteReport(bodyGraph.id)"
                    icon="el-icon-document-remove"
                    type="danger"
                    size="mini"
                    plain
                    round
                  >
                    <span>Delete</span>
                  </el-button>
                  <el-button
                    v-if="!bodyGraph.is_stored"
                    @click="saveReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="success"
                    size="mini"
                    plain
                    round
                  >
                    <span>Save</span>
                  </el-button>
                  <el-button
                    @click="shareReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="mini"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button> -->
                  <el-button
                    v-if="bodyGraph"
                    @click="newReport"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    plain
                    round
                  >
                    <span>New chart</span>
                  </el-button>
                  <el-link
                    download="report.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-button
                    v-if="!isLogged"
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="mini"
                    plain
                    round
                  >
                    <span>Login</span>
                  </el-button>
                </div>
              </el-col>
              <el-col>
                <textarea
                  ref="targetUrl"
                  :value="shareLink"
                  style="height:1px;opacity:0"
                ></textarea>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" :xs="24">
                <div
                  v-html="getMessage('how_to_decode_title')"
                  style="font-weight:500;padding-top:10px;font-size:20px"
                ></div>
                <div v-html="getMessage('how_to_decode')"></div>
              </el-col>
            </el-row>
            <el-collapse
              v-model="reportCollaps.activeTypes"
              @change="changeReportCollaps"
              style="margin:15px 0 20px"
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="collaps-title">About Types</span>
                </template>
                <div v-html="definitionType" class="tip"></div>
              </el-collapse-item>
            </el-collapse>
            <div class="flex-column">
              <el-col :span="24" :xs="24" class="el-col-item">
                <el-card shadow="never" class="min-height-160">
                  <div slot="header" class="clearfix text-align-left">
                    <span>Type: {{ bodyGraph.body_graph.type.title }}</span>
                  </div>
                  <div
                    v-html="bodyGraph.body_graph.type.description"
                    class="text-align-left"
                  ></div>
                  <div class="bottom clearfix">
                    <el-button
                      v-if="
                        bodyGraph.tooltip_type !== 3 && isTurnOnFullDescription
                      "
                      type="primary"
                      @click="fullDescript"
                      plain
                    >
                      Full description
                      <i class="el-icon-document"></i>
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </div>
            <el-collapse
              v-model="reportCollaps.activeStrategy"
              @change="changeReportCollaps"
              style="margin-bottom:20px"
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="collaps-title">About Strategy</span>
                </template>
                <div v-html="definitionStrategy" class="tip"></div>
              </el-collapse-item>
            </el-collapse>
            <div class="flex-column">
              <el-col :span="24" :xs="24" class="el-col-item">
                <el-card shadow="never" class="min-height-160">
                  <div slot="header" class="clearfix text-align-left">
                    <span>
                      Strategy: {{ bodyGraph.body_graph.strategy.title }}
                    </span>
                  </div>
                  <div
                    v-html="bodyGraph.body_graph.strategy.description"
                    class="text-align-left"
                  ></div>
                  <div class="bottom clearfix">
                    <el-button
                      v-if="
                        bodyGraph.tooltip_type !== 3 && isTurnOnFullDescription
                      "
                      type="primary"
                      @click="fullDescript"
                      plain
                    >
                      Full description
                      <i class="el-icon-document"></i>
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </div>
            <el-collapse
              v-model="reportCollaps.activeAuthority"
              @change="changeReportCollaps"
              style="margin-bottom:20px"
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="collaps-title">About Authority</span>
                </template>
                <div v-html="definitionAuthority" class="tip"></div>
              </el-collapse-item>
            </el-collapse>
            <div class="flex-column">
              <el-col :span="24" :xs="24" class="el-col-item">
                <el-card shadow="never" class="min-height-160">
                  <div slot="header" class="clearfix text-align-left">
                    <span>
                      Authority: {{ bodyGraph.body_graph.authority.title }}
                    </span>
                  </div>
                  <div
                    v-html="bodyGraph.body_graph.authority.description"
                    class="text-align-left"
                  ></div>
                  <div class="bottom clearfix">
                    <el-button
                      v-if="
                        bodyGraph.tooltip_type !== 3 && isTurnOnFullDescription
                      "
                      type="primary"
                      @click="fullDescript"
                      plain
                    >
                      Full description
                      <i class="el-icon-document"></i>
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </div>
            <!-- div v-if="isLogged && isEmailConfirmed" -->
            <div>
              <el-collapse
                v-model="reportCollaps.activeFalseSelf"
                @change="changeReportCollaps"
                style="margin-bottom:20px"
              >
                <el-collapse-item name="1">
                  <template slot="title">
                    <span class="collaps-title">About False Self</span>
                  </template>
                  <div v-html="definitionFalseSelf" class="tip"></div>
                </el-collapse-item>
              </el-collapse>
              <div class="flex-column">
                <el-col :span="24" :xs="24" class="el-col-item">
                  <el-card shadow="never" class="min-height-160">
                    <div slot="header" class="clearfix text-align-left">
                      <span>
                        False self: {{ bodyGraph.body_graph.falseself.title }}
                      </span>
                    </div>
                    <div
                      v-html="bodyGraph.body_graph.falseself.description"
                      class="text-align-left"
                    ></div>
                    <div class="bottom clearfix">
                      <el-button
                        v-if="
                          bodyGraph.tooltip_type !== 3 &&
                            isTurnOnFullDescription
                        "
                        type="primary"
                        @click="fullDescript"
                        plain
                      >
                        Full description
                        <i class="el-icon-document"></i>
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
            </div>
            <!-- div v-if="isLogged && isEmailConfirmed" -->
            <div>
              <el-collapse
                v-model="reportCollaps.activeSign"
                @change="changeReportCollaps"
                style="margin-bottom:20px"
              >
                <el-collapse-item name="1">
                  <template slot="title">
                    <span class="collaps-title">About Sign</span>
                  </template>
                  <div v-html="definitionSign" class="tip"></div>
                </el-collapse-item>
              </el-collapse>
              <div class="flex-column">
                <el-col :span="24" :xs="24" class="el-col-item">
                  <el-card shadow="never" class="min-height-160">
                    <div slot="header" class="clearfix text-align-left">
                      <span>Sign: {{ bodyGraph.body_graph.sign.title }}</span>
                    </div>
                    <div
                      v-html="bodyGraph.body_graph.sign.description"
                      class="text-align-left"
                    ></div>
                    <div class="bottom clearfix">
                      <el-button
                        v-if="
                          bodyGraph.tooltip_type !== 3 &&
                            isTurnOnFullDescription
                        "
                        type="primary"
                        @click="fullDescript"
                        plain
                      >
                        Full description
                        <i class="el-icon-document"></i>
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
              <el-collapse
                v-model="reportCollaps.activeProfiles"
                @change="changeReportCollaps"
                style="margin-bottom:20px"
              >
                <el-collapse-item name="1">
                  <template slot="title">
                    <span class="collaps-title">About Profiles</span>
                  </template>
                  <div v-html="definitionProfile" class="tip"></div>
                </el-collapse-item>
              </el-collapse>
              <div class="flex-column">
                <el-col :span="24" :xs="24" class="el-col-item">
                  <el-card shadow="never" class="min-height-160">
                    <div slot="header" class="clearfix text-align-left">
                      <span>
                        Profile: {{ bodyGraph.body_graph.profile.title }}
                      </span>
                    </div>
                    <div
                      v-html="bodyGraph.body_graph.profile.description"
                      class="text-align-left"
                    ></div>
                    <div class="bottom clearfix">
                      <el-button
                        v-if="
                          bodyGraph.tooltip_type !== 3 &&
                            isTurnOnFullDescription
                        "
                        type="primary"
                        @click="fullDescript"
                        plain
                      >
                        Full description
                        <i class="el-icon-document"></i>
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
              <el-collapse
                v-model="reportCollaps.activeDefinition"
                @change="changeReportCollaps"
                style="margin-bottom:20px"
              >
                <el-collapse-item name="1">
                  <template slot="title">
                    <span class="collaps-title">About Definition</span>
                  </template>
                  <div v-html="definitionDefinition" class="tip"></div>
                </el-collapse-item>
              </el-collapse>
              <!-- <el-collapse v-model="activeAngle" style="margin-bottom:20px">
                <el-collapse-item title="About Angle" name="1">
                  <div v-html="definitionAngle" class="tip"></div>
                </el-collapse-item>
              </el-collapse> -->
              <div class="flex-column">
                <el-col :span="24" :xs="24" class="el-col-item">
                  <el-card shadow="never" class="min-height-160">
                    <div slot="header" class="clearfix text-align-left">
                      <span>
                        Definition: {{ bodyGraph.body_graph.definition.title }}
                      </span>
                    </div>
                    <div
                      v-html="bodyGraph.body_graph.definition.description"
                      class="text-align-left"
                    ></div>
                    <div class="bottom clearfix">
                      <el-button
                        v-if="
                          bodyGraph.tooltip_type !== 3 &&
                            isTurnOnFullDescription
                        "
                        type="primary"
                        @click="fullDescript"
                        plain
                      >
                        Full description
                        <i class="el-icon-document"></i>
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
                <!-- <el-col :span="24" :xs="24" class="el-col-item">
                  <el-card shadow="never" class="min-height-160">
                    <div slot="header" class="clearfix text-align-left">
                      <span>Angle: {{ bodyGraph.body_graph.angle.title }}</span>
                    </div>
                    <div
                      v-html="bodyGraph.body_graph.angle.description"
                      class="text-align-left"
                    ></div>
                    <div class="bottom clearfix">
                      <el-button
                        v-if="
                          bodyGraph.tooltip_type !== 3 && isTurnOnFullDescription
                        "
                        type="primary"
                        @click="fullDescript"
                        plain
                      >
                        Full description
                        <i class="el-icon-document"></i>
                      </el-button>
                    </div>
                  </el-card>
                </el-col> -->
              </div>
              <div class="display-inline-block">
                <h3 class="font-weigt-300">
                  Centers:
                </h3>
                <el-tabs v-model="activeCenters" stretch>
                  <el-tab-pane label="All" name="all">
                    <el-collapse
                      v-model="reportCollaps.activeAllCenters"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">About All Centers</span>
                        </template>
                        <div v-html="definitionCentersAll" class="tip"></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div v-for="(center, i) in centersAll" :key="i">
                        <el-col :span="24" :xs="24" class="el-col-item">
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ center.title }}</span>
                            </div>
                            <div
                              v-html="center.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Defined Centers" name="defined">
                    <el-collapse
                      v-model="reportCollaps.activeDefinedCenters"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">
                            About Defined Centers
                          </span>
                        </template>
                        <div
                          v-html="definitionCentersDefined"
                          class="tip"
                        ></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div v-for="(center, i) in centersDefined" :key="i">
                        <el-col :span="24" :xs="24" class="el-col-item">
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ center.title }}</span>
                            </div>
                            <div
                              v-html="center.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Undefined Centers" name="undefined">
                    <el-collapse
                      v-model="reportCollaps.activeUndefinedCenters"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">
                            About Undefined Centers
                          </span>
                        </template>
                        <div
                          v-html="definitionCentersUndefined"
                          class="tip"
                        ></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div v-for="(center, i) in centersUndefined" :key="i">
                        <el-col :span="24" :xs="24" class="el-col-item">
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ center.title }}</span>
                            </div>
                            <div
                              v-html="center.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div
                v-if="isChannelsGates && isLogged"
                class="display-inline-block"
              >
                <!-- h3 v-if="isLogged && isEmailConfirmed" class="font-weigt-300" -->
                <h3 v-if="isLogged" class="font-weigt-300">
                  Channels:
                </h3>
                <el-collapse
                  v-if="isLogged"
                  v-model="reportCollaps.activeChannels"
                  @change="changeReportCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">About Channels</span>
                    </template>
                    <div v-html="definitionChannels" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div v-if="isLogged" class="flex-column2">
                  <div
                    v-for="(channel, i) in bodyGraph.body_graph.channels2"
                    :key="i"
                  >
                    <el-col :span="24" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ channel.title }}</span>
                        </div>
                        <div
                          v-html="channel.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              bodyGraph.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </div>
              <div class="display-inline-block" v-if="isChannelsGates">
                <!-- h3 v-if="isLogged && isEmailConfirmed" class="font-weigt-300"></h3 -->
                <h3 v-if="isLogged" class="font-weigt-300">
                  Gates:
                </h3>
                <el-tabs v-if="isLogged" v-model="activeGates" stretch>
                  <el-tab-pane label="All" name="all">
                    <el-collapse
                      v-model="reportCollaps.activeAllGates"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">About Gates</span>
                        </template>
                        <div v-html="definitionGatesAll" class="tip"></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div
                        v-for="(gate, i) in bodyGraph.body_graph.gatesExt"
                        :key="i"
                      >
                        <el-col
                          v-if="!gate.is_part_of_channel"
                          :span="24"
                          :xs="24"
                          class="el-col-item"
                        >
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ gate.title }}</span>
                            </div>
                            <div
                              v-html="gate.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Subconscious" name="subconscious">
                    <el-collapse
                      v-model="reportCollaps.activeSubconscious"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">
                            About Subconscious Gates
                          </span>
                        </template>
                        <div
                          v-html="definitionGatesSubconscious"
                          class="tip"
                        ></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div v-for="(gate, i) in gatesSubconscious" :key="i">
                        <el-col :span="24" :xs="24" class="el-col-item">
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ gate.title }}</span>
                            </div>
                            <div
                              v-html="gate.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Conscious" name="conscious">
                    <el-collapse
                      v-model="reportCollaps.activeConscious"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">
                            About Conscious Gates
                          </span>
                        </template>
                        <div
                          v-html="definitionGatesConscious"
                          class="tip"
                        ></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div v-for="(gate, i) in gatesConscious" :key="i">
                        <el-col :span="24" :xs="24" class="el-col-item">
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ gate.title }}</span>
                            </div>
                            <div
                              v-html="gate.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Both" name="both">
                    <el-collapse
                      v-model="reportCollaps.activeBoth"
                      @change="changeReportCollaps"
                      style="margin-bottom:20px"
                    >
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span class="collaps-title">
                            About Both Gates
                          </span>
                        </template>
                        <div v-html="definitionGatesBoth" class="tip"></div>
                      </el-collapse-item>
                    </el-collapse>
                    <div class="flex-column2">
                      <div v-for="(gate, i) in gatesBoth" :key="i">
                        <el-col :span="12" :xs="24" class="el-col-item">
                          <el-card shadow="never" class="min-height-160">
                            <div slot="header" class="clearfix text-align-left">
                              <span>{{ gate.title }}</span>
                            </div>
                            <div
                              v-html="gate.tooltip"
                              class="text-align-left"
                            ></div>
                            <div class="bottom clearfix">
                              <el-button
                                v-if="
                                  bodyGraph.tooltip_type !== 3 &&
                                    isTurnOnFullDescription
                                "
                                type="primary"
                                @click="fullDescript"
                                plain
                              >
                                Full description
                                <i class="el-icon-document"></i>
                              </el-button>
                            </div>
                          </el-card>
                        </el-col>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div v-if="!isLogged" class="login-wrapper-desk">
              <h3 class="font-weigt-300">{{ restOfInformation }}</h3>
              <Login
                :closeButton.sync="closeButtonAttr"
                :email.sync="email"
                :activeNameTab.sync="activeNameTab"
                @close="closeDialog"
              />
            </div>
            <!-- div v-if="rightPartnerId" class="login-wrapper-desk" -->
            <div class="login-wrapper-desk">
              <h3 class="font-weigt-300 margin-bt-10">
                {{ sendApplyForChartOfInformation }}
              </h3>
              <!-- <el-link
                :href="sendApplayForChartFormLink"
                target="_blank"
                type="primary2"
              >
                View Content
              </el-link> -->
              <el-button @click="scrollToInfoBlock()" type="primary2">
                View content
              </el-button>
              &nbsp;
              <el-button @click="openOrderDialog" type="green">
                Make order
              </el-button>
            </div>
            <hr class="hr-custom" />
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <div class="no-mobile">
                  <el-button
                    v-if="bodyGraph"
                    @click="newReport"
                    type="primary"
                    icon="el-icon-plus"
                    size="medium"
                    plain
                    round
                  >
                    <span>New chart</span>
                  </el-button>
                  <el-button
                    v-if="bodyGraph.is_stored && isLogged"
                    @click="deleteReport(bodyGraph.id)"
                    icon="el-icon-document-remove"
                    type="danger"
                    size="medium"
                    plain
                    round
                  >
                    <span>Delete report</span>
                  </el-button>
                  <el-button
                    v-if="!bodyGraph.is_stored"
                    @click="saveReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="success"
                    size="medium"
                    plain
                    round
                  >
                    <span>Save report</span>
                  </el-button>
                  <el-button
                    v-if="isLogged"
                    @click="shareReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="medium"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-button
                    v-else
                    @click="showDialog = true"
                    icon="el-icon-document-add"
                    type="warning"
                    size="medium"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <!-- <el-link
                    v-if="isLogged"
                    download="report.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-link
                    v-else
                    @click="showDialog = true"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link> -->
                  <el-button
                    v-if="isLogged"
                    @click="goToProfile"
                    type="primary"
                    icon="el-icon-user"
                    size="medium"
                    plain
                    round
                  >
                    <span>Profile</span>
                  </el-button>
                  <el-button
                    v-else
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="medium"
                    plain
                    round
                  >
                    <span>Login/Register</span>
                  </el-button>
                </div>
                <div class="no-desktop">
                  <el-button
                    v-if="bodyGraph"
                    @click="newReport"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    plain
                    round
                  >
                    <span>New</span>
                  </el-button>
                  <el-button
                    v-if="bodyGraph.is_stored && isLogged"
                    @click="deleteReport(bodyGraph.id)"
                    icon="el-icon-document-remove"
                    type="danger"
                    size="mini"
                    plain
                    round
                  >
                    <span>Delete</span>
                  </el-button>
                  <el-button
                    v-if="!bodyGraph.is_stored"
                    @click="saveReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="success"
                    size="mini"
                    plain
                    round
                  >
                    <span>Save</span>
                  </el-button>
                  <el-button
                    v-if="isLogged"
                    @click="shareReport(bodyGraph.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="mini"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-button
                    v-else
                    @click="showDialog = true"
                    icon="el-icon-document-add"
                    type="warning"
                    size="mini"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <!-- <el-link
                    download="report.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link> -->
                  <el-button
                    v-if="isLogged"
                    @click="goToProfile"
                    type="primary"
                    icon="el-icon-switch-button"
                    size="mini"
                    plain
                    round
                  >
                    <span>Profile</span>
                  </el-button>
                  <el-button
                    v-else
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="mini"
                    plain
                    round
                  >
                    <span>Login</span>
                  </el-button>
                </div>
              </el-col>
              <el-col>
                <textarea
                  ref="targetUrl"
                  :value="shareLink"
                  style="height:1px;opacity:0"
                ></textarea>
              </el-col>
            </el-row>
            <!-- <h3>Quarter & Angle:</h3>
            <div class="flex-column">
              <el-col :span="24" :xs="24" class="el-col-item">
                <el-card shadow="never" class="min-height-160">
                  <div slot="header" class="clearfix text-align-left">
                    <span>
                      Quarter: {{ bodyGraph.body_graph.quarter.title }}
                    </span>
                  </div>
                  <div
                    v-html="bodyGraph.body_graph.quarter.description"
                    class="text-align-left"
                  ></div>
                  <div class="bottom clearfix">
                    <el-button
                      v-if="
                        bodyGraph.tooltip_type !== 3 &&
                          isTurnOnFullDescription
                      "
                      type="primary"
                      @click="fullDescript"
                      plain
                    >
                      Full description
                      <i class="el-icon-document"></i>
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </div> -->
          </div>
        </el-card>
      </el-col>
    </el-row>
    <SendApplayForChartDialog
      :SendApplayForChartIncome="SendApplayForChartIncome"
      @close="closeSendApplayForChart"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import ReportForm from "@/components/forms/ReportForm";
import BodyGraph from "@/components/BodyGraph";
import InfoDialog from "@/components/dialogs/InfoDialog";
import OrderDialog from "@/components/dialogs/OrderDialog";
import Login from "@/components/forms/Login";
import LoginDialog from "@/components/dialogs/LoginDialog";
import RemindDialog from "@/components/dialogs/RemindDialog";
import AfterSuccessRegDialog from "@/components/dialogs/AfterSuccessRegDialog";
import TooltipDialog from "@/components/dialogs/TooltipDialog";
import TooltipUnderConstructionDialog from "@/components/dialogs/TooltipUnderConstructionDialog";
import EmailConfirmedDialog from "@/components/dialogs/EmailConfirmedDialog";
import ErrorDialog from "@/components/dialogs/ErrorDialog";
import SendApplayForChartDialog from "@/components/dialogs/SendApplayForChartDialog";

export default {
  name: "PoindOfEntry",
  props: {
    msg: String
  },
  components: {
    ReportForm,
    BodyGraph,
    InfoDialog,
    OrderDialog,
    LoginDialog,
    RemindDialog,
    EmailConfirmedDialog,
    Login,
    AfterSuccessRegDialog,
    TooltipDialog,
    TooltipUnderConstructionDialog,
    SendApplayForChartDialog,
    ErrorDialog
  },
  data() {
    return {
      showDialog: false,
      showRemindDialog: false,
      showSuccessDialog: false,
      showTooltipDialog: {},
      showEmailDialog: { show: false },
      showTooltipUnderConstructionDialog: false,
      showErrorDialog: { show: false },
      activeCenters: "all",
      activeGates: "all",
      gatesSubconscious: [],
      gatesConscious: [],
      gatesBoth: [],
      centersDefined: [],
      centersUndefined: [],
      centersAll: [],
      restOfInformation:
        "The rest of the information is available only to registered users.",
      sendApplyForChartOfInformation:
        "You can order a complete decoding your chart in one document here",
      messageIncome:
        "The mail has been sent successfully. All mails are processed in the order of arrival. On average, it takes from 2 to 4 hours.",
      closeButtonAttr: false,
      infoDialog: false,
      orderDialog: false,
      shareLink: "",
      image: "",
      png: "",
      isTurnOnFullDescription: false,
      SendApplayForChartIncome: false,
      sendApplayForChartFormLink:
        "https://life-chart.art/#/?anchor=DesignTranscribed",
      rightPartnerId: false
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    isEmailConfirmed: {
      get() {
        return this.$store.getters.IS_EMAIL_CONFIRMED;
      },
      set(value) {
        this.$store.commit("IS_EMAIL", value);
      }
    },
    isUnderConstruction: {
      get() {
        return this.$store.getters.IS_UNDER_CONSTRUCTION;
      },
      set(value) {
        this.$store.commit("IS_UNDER_CONSTR", value);
      }
    },
    isChannelsGates: {
      get() {
        return this.$store.getters.IS_CHANNELS_GATES;
      },
      set(value) {
        this.$store.commit("IS_CHANNELS_GATES", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    email: {
      get() {
        return this.$store.state.User.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      }
    },
    activeNameTab: {
      get() {
        return this.$store.state.User.activeNameTab;
      },
      set(value) {
        this.$store.commit("SET_ACTIVE_NAME_TAB", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    reportCollaps() {
      return this.$store.state.ReportCollaps;
    },
    bodyGraph() {
      return this.User.chartBodygraph;
    },
    definitionType() {
      return this.getMessage("definition_type");
    },
    definitionProfile() {
      return this.getMessage("definition_profile");
    },
    definitionAuthority() {
      return this.getMessage("definition_authority");
    },
    definitionStrategy() {
      return this.getMessage("definition_strategy");
    },
    definitionFalseSelf() {
      return this.getMessage("definition_false_self");
    },
    definitionSign() {
      return this.getMessage("definition_sign");
    },
    definitionDefinition() {
      return this.getMessage("definition_definition");
    },
    definitionAngle() {
      return this.getMessage("definition_angle");
    },
    definitionCentersAll() {
      return this.getMessage("definition_all_centers");
    },
    definitionCentersDefined() {
      return this.getMessage("definition_defined_centers");
    },
    definitionCentersUndefined() {
      return this.getMessage("definition_undefined_centers");
    },
    definitionChannels() {
      return this.getMessage("definition_channels");
    },
    definitionGatesAll() {
      return this.getMessage("definition_all_gates");
    },
    definitionGatesSubconscious() {
      return this.getMessage("definition_subconscious_gates");
    },
    definitionGatesConscious() {
      return this.getMessage("definition_conscious_gates");
    },
    definitionGatesBoth() {
      return this.getMessage("definition_both_gates");
    }
  },
  mounted() {
    this.initApp();
    this.getSettings();
    this.getProfile();
    this.checkRouteQueries();
    this.initReport();
    this.mathingCenters();
    setTimeout(() => {
      this.getAndStoreUserQueries();
      if (this.bodyGraph) this.downloadReport();
      this.getPartnerId();
      this.defineRightPartnerId();
    }, 100);
  },
  methods: {
    ...mapMutations([
      "SET_BODYGRAPH",
      "SET_COMPOSITE",
      "SET_TRANSIT",
      "SET_INFLICTION",
      "IS_EMAIL",
      "SET_PARTNER_ID"
    ]),
    async getSettings() {
      try {
        const result = await Api.getSettings();
        if (result.success) {
          // console.log(result.messages);
          this.$store.commit("SET_MESSAGES", result.messages);
          // console.log(this.$store.state.Messages);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getProfile() {
      if (this.isLogged) {
        try {
          const result = await Api.getProfile();
          if (result.success) {
            if (result.profile.is_email_confirmed) {
              this.IS_EMAIL(true);
            } else {
              this.IS_EMAIL(false);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async checkRouteQueries() {
      if (this.isLogged) {
        if (
          Object.keys(this.$route.query).length !== 0 &&
          this.$route.query.email_hash
        ) {
          try {
            const result = await Api.getConfirmEmail(
              this.$route.query.email_hash
            );
            if (result.success) {
              // console.log(result);
              this.IS_EMAIL(true);
              this.showEmailDialog = {
                show: true,
                message: "Thank You, your email activated"
              };
            }
          } catch (e) {
            this.showErrorDialog = this.errorHandler(e);
          }
        }
        if (
          Object.keys(this.$route.query).length !== 0 &&
          this.$route.query.report_hash
        ) {
          try {
            const result = await Api.getSharedRaveReport(
              this.$route.query.report_hash
            );
            if (result.success) {
              // console.log(result);
              this.SET_BODYGRAPH(result.report);
              this.matchChannels();
              // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
              localStorage.setItem(
                "chartBodygraph",
                JSON.stringify(result.report)
              );
            }
          } catch (e) {
            console.log(e);
          }
        }
        if (
          Object.keys(this.$route.query).length !== 0 &&
          this.$route.query.composite_hash
        ) {
          try {
            const result = await Api.getSharedCompositeReport(
              this.$route.query.composite_hash
            );
            if (result.success) {
              // console.log(result);
              this.SET_COMPOSITE(result.report);
              // Save to localStorage chartComposite, Tooltips, Report Id & Stored
              localStorage.setItem(
                "chartComposite",
                JSON.stringify(result.report)
              );
              this.$router.push("/composite");
            }
          } catch (e) {
            console.log(e);
          }
        }
        if (
          Object.keys(this.$route.query).length !== 0 &&
          this.$route.query.transit_hash
        ) {
          try {
            const result = await Api.getSharedTransitReport(
              this.$route.query.transit_hash
            );
            if (result.success) {
              // console.log(result);
              this.SET_TRANSIT(result.report);
              this.$router.push("/transit");
            }
          } catch (e) {
            console.log(e);
          }
        }
        if (
          Object.keys(this.$route.query).length !== 0 &&
          this.$route.query.infliction_hash
        ) {
          try {
            const result = await Api.getSharedInflictionReport(
              this.$route.query.infliction_hash
            );
            if (result.success) {
              // console.log(result);
              this.createTransit(
                result.report.birthday2,
                result.report.city2.id
              );
              this.SET_INFLICTION(result.report);
              this.$router.push("/transit");
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    async createTransit(birthday, city) {
      try {
        if (!city) {
          city = 18009;
        }
        const reportParams = {
          city_id: city,
          transit_birthday: birthday
        };
        const result = await Api.createTransit(reportParams);
        if (result.success) {
          this.SET_TRANSIT(result.report);
        }
      } catch (e) {
        console.log(e);
      }
    },
    fullDescript() {
      if (this.isLogged) {
        this.infoDialog = true;
      } else {
        this.showDialog = true;
      }
    },
    initReport() {
      this.getItemFromLocalStorage("reportForm", "SET_REPORT_FORM");
      this.getItemFromLocalStorage("reportCity", "SET_REPORT_CITY_ID");
      this.getItemFromLocalStorage("reportCollaps", "SET_REPORT_COLLAPS");
      this.getItemFromLocalStorage("chartBodygraph", "SET_BODYGRAPH");
      this.mathingGates();
    },
    mathingGates() {
      // console.log(this.bodyGraph);
      if (this.bodyGraph) {
        const gates = Object.values(this.bodyGraph.body_graph.gatesExt);
        this.bodyGraph.body_graph.gatesActivatedAtDesignOnly.forEach(
          gateAtDesignOnly => {
            gates.forEach(gate => {
              if (+gateAtDesignOnly === +gate.gate)
                this.gatesSubconscious.push(gate);
            });
          }
        );
        this.bodyGraph.body_graph.gatesActivatedAtPersonalityOnly.forEach(
          gateAtPersonalityOnly => {
            gates.forEach(gate => {
              if (+gateAtPersonalityOnly === +gate.gate)
                this.gatesConscious.push(gate);
            });
          }
        );
        this.bodyGraph.body_graph.gatesActivatedAtPersonalityAndDesign.forEach(
          gateAtPersonalityAndDesign => {
            gates.forEach(gate => {
              if (+gateAtPersonalityAndDesign === +gate.gate)
                this.gatesBoth.push(gate);
            });
          }
        );
      }
    },
    mathingCenters() {
      // console.log(this.bodyGraph);
      if (this.bodyGraph) {
        const centersDefined = Object.values(
          this.bodyGraph.body_graph.centersDefinedExt
        );
        const centersUndefined = Object.values(
          this.bodyGraph.body_graph.centersUndefinedExt
        );
        if (Array.isArray(this.bodyGraph.body_graph.centersDefined)) {
          this.bodyGraph.body_graph.centersDefined.forEach(centerDefined => {
            centersDefined.forEach((center, index) => {
              center.id = index + 1;
              if (+centerDefined === index + 1)
                this.centersDefined.push(center);
            });
          });
        } else {
          const obj = this.bodyGraph.body_graph.centersDefined;
          for (let key in obj) {
            centersDefined.forEach((center, index) => {
              center.id = index + 1;
              if (+obj[key] === index + 1) this.centersDefined.push(center);
            });
          }
        }
        this.bodyGraph.body_graph.centersUndefinedAndOpen.forEach(
          centerUndefinedAndOpen => {
            centersUndefined.forEach((center, index) => {
              center.id = index + 1;
              if (+centerUndefinedAndOpen === index + 1)
                this.centersUndefined.push(center);
            });
          }
        );
        this.centersAll = this.centersDefined.concat(this.centersUndefined);
        this.centersAll.sort((a, b) => (a.id > b.id ? 1 : -1));
      }
    },
    async getExit() {
      this.name = "";
      this.token = "life-chart-token";
      this.isLogged = false;
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      // localStorage.removeItem("chartBodygraph");
      /* try {
        const data = {};
        const result = await Api.logout(data);
        if (result.success) {
          this.name = "";
          this.token = "life-chart-token";
          this.isLogged = false;
          localStorage.removeItem("name");
          localStorage.removeItem("token");
        }
      } catch (e) {
        console.log(e);
      } */
    },
    goToProfile() {
      this.$router.push("/profile");
      setTimeout(() => {
        this.scrollToTop();
      }, 250);
    },
    defineRightPartnerId() {
      this.rightPartnerId =
        this.$store.state.http_options.headers["Partner-Id"] &&
        +this.$store.state.http_options.headers["Partner-Id"] !== 1;
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    },
    closeRemindDialog() {
      this.showRemindDialog = false;
    },
    closeSuccessRegDialog(v) {
      this.showSuccessDialog = v;
    },
    closeInfoDialog() {
      this.infoDialog = false;
    },
    openOrderDialog() {
      this.orderDialog = true;
    },
    closeOrderDialog() {
      this.orderDialog = false;
    },
    incomeTooltipsObj(obj) {
      // console.log(obj);
      if (this.isUnderConstruction) {
        // this.showTooltipUnderConstructionDialog = true;
        return;
      } else {
        if (this.isLogged) {
          this.showTooltipDialog = {
            show: true,
            tooltip: obj
          };
        } /* else if (this.isLogged && !this.isEmailConfirmed) {
          this.showEmailDialog = {
            show: true,
            message: "You need to confirm your email"
          };
        } */ else {
          this.showDialog = true;
        }
        /* this.showTooltipDialog = {
          show: true,
          tooltip: obj
        }; */
      }
    },
    closeEmailDialog() {
      this.showEmailDialog = { show: false };
    },
    closeTooltipDialog(obj) {
      this.showTooltipDialog = obj;
    },
    closeTooltipUnderDialog() {
      this.showTooltipUnderConstructionDialog = false;
    },
    closeErrorDialog(obj) {
      this.showErrorDialog = obj;
    },
    newReport() {
      localStorage.removeItem("chartBodygraph");
      this.SET_BODYGRAPH(null);
      this.scrollToTop();
    },
    async saveReport(id) {
      if (this.isLogged) {
        try {
          const reportParams = {
            report_id: id
          };
          const result = await Api.saveReport(reportParams);
          if (result.success) {
            console.log(result.report);
            this.bodyGraph.is_stored = 1;
            // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
            localStorage.setItem(
              "chartBodygraph",
              JSON.stringify(this.bodyGraph)
            );
            this.mathingGates();
            this.mathingCenters();
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.showDialog = true;
      }
    },
    async deleteReport(id) {
      try {
        const reportParams = {
          report_id: id
        };
        const result = await Api.deleteReport(reportParams);
        if (result.success) {
          // console.log(result.report);
          this.bodyGraph.is_stored = 0;
          localStorage.setItem(
            "chartBodygraph",
            JSON.stringify(this.bodyGraph)
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    async shareReport(id, isUserProduct) {
      try {
        this.shareLink = "";
        const result = isUserProduct
          ? await Api.shareUserProduct({ user_product_id: id })
          : await Api.shareRaveReport({ report_id: id });
        if (result.success) {
          this.shareLink = result.share_link;
          // console.log(this.shareLink);
          setTimeout(() => {
            this.$refs.targetUrl.select();
            document.execCommand("copy");
          }, 100);
          this.$message({
            message: "Link to share copied",
            type: "success"
          });
        }
      } catch (e) {
        console.log(e);
      }
      /* if (this.isLogged) {
        
      } else {
        this.showDialog = true;
      } */
    },
    downloadReport() {
      const svgElement = document.getElementById("svg1582");
      let { width, height } = svgElement.getBBox();
      // true for deep clone
      let clonedSvgElement = svgElement.cloneNode(true);
      let outerHTML = clonedSvgElement.outerHTML,
        blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });
      let URL = window.URL || window.webkitURL || window;
      let blobURL = URL.createObjectURL(blob);
      let image = new Image();
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = Math.round(width);
        canvas.height = Math.round(height);
        let context = canvas.getContext("2d");
        // draw image in canvas starting left-0 , top - 0
        context.drawImage(image, 0, 0, Math.round(width), Math.round(height));
        this.png = canvas.toDataURL();
        // console.log("png: ", this.png);
      };
      image.src = blobURL;
    },
    changeReportCollaps() {
      this.setItemToLocalStorage(
        "reportCollaps",
        JSON.stringify(this.reportCollaps)
      );
    },
    getPartnerId() {
      const instance = document.getElementById("wiget");
      if (instance) {
        this.SET_PARTNER_ID(instance.dataset.partnerId);
        // console.log("instance is: ", instance.dataset.partnerId);
      }
    },
    mathingGatesCentersAndDownloadReport(v) {
      if (v) {
        this.mathingGates();
        this.mathingCenters();
        setTimeout(() => {
          if (this.bodyGraph) this.downloadReport();
        }, 100);
      }
    },
    /* sendApplayForChartForm() {
      // this.SendApplayForChartIncome = true;
      this.$router.push("/decoding");
      this.scrollToTop();
    }, */
    getAndStoreUserQueries() {
      // Open Order Dialog if query is true
      if (this.$route.query.order_dialog) {
        this.openOrderDialog();
        setTimeout(() => {
          this.scrollToInfoBlock();
        }, 0);
      }
      if (this.$route.query.anchor === "DesignTranscribed") {
        setTimeout(() => {
          this.scrollToInfoBlock();
        }, 2500);
      }
    },
    closeSendApplayForChart() {
      this.SendApplayForChartIncome = false;
    }
  }
};
</script>
<style scoped>
.city {
  display: flex;
  align-items: center;
}
.city-right-side {
  display: flex;
  margin-bottom: 10px;
}
.city-title {
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.country-title {
  font-size: 12px;
  color: #afafaf;
  padding-left: 7px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-align-left {
  text-align: left;
}
.flex {
  display: flex;
}
.padding-lt-0 {
  padding-left: 0 !important;
}
.padding-rt-0 {
  padding-right: 0 !important;
}
.width-100 {
  width: 100%;
}
.justify-end {
  justify-content: end;
}
.report-form {
  min-width: 500px;
  max-width: 500px;
}
.report {
  min-width: 500px !important;
  max-width: 920px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-column {
  display: flex;
}
.el-col-item {
  margin-bottom: 20px;
}
.flex-column .el-col-item,
.flex-column2 .el-col-item {
  align-self: stretch;
}
.flex-column .el-col-item .el-card,
.flex-column2 .el-col-item .el-card {
  height: 100%;
}
.tip {
  padding: 8px 16px;
  background-color: #f0f0fd;
  border-radius: 4px;
  border-left: 5px solid #bcbeff;
  margin: 15px 10px;
  text-align: left;
  font-size: 16px;
  color: #4c4e86;
  line-height: 1.5em;
}
.width-70 {
  width: 70%;
}
.login-wrapper-desk {
  margin: 0 70px;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .flex-column,
  .flex-column2 {
    flex-direction: column;
  }
  .flex-column .el-col-item,
  .flex-column2 .el-col-item {
    align-self: start;
  }
  .report-form,
  .report {
    min-width: initial;
  }
  .width-100-m {
    width: 100%;
  }
  .login-wrapper-desk {
    margin: 0;
  }
  .report-form {
    min-width: 320px;
    max-width: 430px;
  }
  .report {
    min-width: 320px !important;
    max-width: 430px !important;
  }
}
</style>
