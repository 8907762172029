<template>
  <div class="about">
    <OrderDialog
      :orderDialogIncome.sync="orderDialog"
      :isComposite="$route.path === '/composite'"
      @closeOrderDialog="closeOrderDialog"
    />
    <el-dialog
      :title="tooltipsDialogTitle"
      :visible.sync="showTooltipDialogIncome.show"
      :before-close="closeDialog"
      width="100%"
      top="3vh"
      cancel-button-class="false_1"
      append-to-body
      class="common-dialog tooltip-dialog"
    >
      <el-row
        type="flex"
        justify="center"
        :gutter="20"
        class="xs-flex-direction-column"
      >
        <div v-html="tooltipsDialogHtml"></div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <!-- <div v-if="rightPartnerId" class="text-align-center"> -->
        <div v-if="isOrderBlock" class="text-align-center">
          <h3 class="title-dialog-ad-info">
            {{ sendApplyForChartOfInformation }}
          </h3>
          <!-- <el-button @click="closeDialog" class="min-width-95">Close</el-button> -->
          <el-button
            @click="
              closeDialog();
              scrollToInfoBlock();
            "
            type="primary2"
            class="min-width-125 margin-right-5"
          >
            View content
          </el-button>
          <el-button
            @click="openOrderDialog"
            class="min-width-125 margin-left-5"
            type="green"
          >
            Make order
          </el-button>
        </div>
        <div v-else class="text-align-center">
          <el-button @click="closeDialog" class="min-width-95">Close</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import OrderDialog from "@/components/dialogs/OrderDialog";

export default {
  name: "showTooltipDialog",
  props: {
    showTooltipDialogIncome: {
      type: Object,
      default: null
    },
    isOrderBlock: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrderDialog
  },
  data() {
    return {
      orderDialog: false,
      tooltipsDialogTitle: "",
      tooltipsDialogHtml: "",
      sendApplyForChartOfInformation:
        "You can order a complete decoding your chart in one document here",
      sendApplayForChartFormLink:
        "https://life-chart.art/#/?anchor=DesignTranscribed",
      rightPartnerId: false
    };
  },
  mixins: [commonReportingMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    nameOfReport() {
      const chartBodygraph = JSON.parse(localStorage.getItem("chartBodygraph"));
      return this.User.reportName
        ? this.User.reportName
        : chartBodygraph
        ? chartBodygraph.first_name
        : "";
    },
    emailIncome() {
      return this.User.email ? this.User.email : localStorage.getItem("email");
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.getPartnerId();
      this.defineRightPartnerId();
    }, 100);
  },
  watch: {
    showTooltipDialogIncome() {
      this.tooltipsDialogTitle = this.showTooltipDialogIncome.tooltip.title;
      this.tooltipsDialogHtml = this.showTooltipDialogIncome.tooltip.tooltip;
    }
  },
  methods: {
    ...mapMutations(["SET_PARTNER_ID"]),
    closeDialog() {
      this.$emit("closeToolDialog", { show: false, tooltip: { title: "" } });
    },
    getPartnerId() {
      const instance = document.getElementById("wiget");
      if (instance) {
        this.SET_PARTNER_ID(instance.dataset.partnerId);
        // console.log("instance is: ", instance.dataset.partnerId);
      }
    },
    defineRightPartnerId() {
      this.rightPartnerId =
        this.$store.state.http_options.headers["Partner-Id"] &&
        +this.$store.state.http_options.headers["Partner-Id"] !== 1;
    },
    openOrderDialog() {
      this.orderDialog = true;
    },
    closeOrderDialog() {
      this.orderDialog = false;
    }
  }
};
</script>
