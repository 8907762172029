<template>
  <div>
    <OrderDialog
      :orderDialogIncome.sync="orderDialog"
      :isComposite="true"
      @closeOrderDialog="closeOrderDialog"
    />
    <InfoDialog
      :infoDialogIncome.sync="infoDialog"
      :messageIncome="messageIncome"
      @openCloseInfoDialog="closeInfoDialog"
    />
    <AfterSuccessRegDialog
      :afterSuccessRegDialog.sync="showSuccessDialog"
      @closeSucRegDialog="closeSuccessRegDialog"
    />
    <LoginDialog
      :showDialogIncome.sync="showDialog"
      :email.sync="email"
      :activeNameTab.sync="activeNameTab"
      @openRemind="showRemindDialog = true"
      @close="closeDialog"
    />
    <RemindDialog
      :showRemindDialogIncome.sync="showRemindDialog"
      :email.sync="email"
      @close="closeRemindDialog"
    />
    <TooltipUnderConstructionDialog
      :showTooltipUnderConstructionDialogIncome.sync="
        showTooltipUnderConstructionDialog
      "
      @closeTooltipUnderDialog="closeTooltipUnderDialog"
    />
    <TooltipDialog
      :showTooltipDialogIncome.sync="showTooltipDialog"
      :isOrderBlock="true"
      @closeToolDialog="closeTooltipDialog"
    />
    <EmailConfirmedDialog
      :showEmailDialogIncome.sync="showEmailDialog"
      :isOrderBlock="true"
      @closeEmailD="closeEmailDialog"
    />
    <ErrorDialog
      :showErrorDialogIncome.sync="showErrorDialog"
      @closeErrorD="closeErrorDialog"
    />
    <el-row v-if="!compoSite" type="flex" justify="center" :gutter="20">
      <el-col :span="12" :xs="24" :xl="12" class="flex-center">
        <CompositeForm
          @matchCompositeChannelsAnddownloadComposite="
            matchCompositeChannelsAnddownloadComposite
          "
        />
      </el-col>
    </el-row>
    <el-row v-else type="flex" justify="center" :gutter="20">
      <el-col :xs="24" :xl="24" class="report">
        <el-card class="box-card el-card-with-bg">
          <div slot="header" class="clearfix">
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <div class="no-mobile">
                  Your composite: {{ compoSite.first_name }}
                  {{ compoSite.birthday }}, {{ compoSite.first_name2 }}
                  {{ compoSite.birthday2 }}
                </div>
                <div class="no-desktop">
                  Your composite:<br />
                  {{ compoSite.first_name }} {{ compoSite.birthday }}<br />
                  {{ compoSite.first_name2 }} {{ compoSite.birthday2 }}
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <el-button
                  @click="openOnlyReport(1)"
                  :class="{
                    'users-cards-active': isChartTurnOn,
                    'pulse-item': isFirstTimeComp
                  }"
                  class="users-cards"
                >
                  {{ compoSite.first_name }}
                </el-button>
                <el-button
                  @click="openOnlyReport(2)"
                  :class="{
                    'users-cards-active': isChart2TurnOn,
                    'pulse-item': isFirstTimeComp
                  }"
                  class="users-cards2"
                >
                  {{ compoSite.first_name2 }}
                </el-button>
              </el-col>
            </el-row>
            <div class="body-graph-wrapper">
              <CompoSite
                :compoSite.sync="compoSite"
                :isChartTurnOn.sync="isChartTurnOn"
                :isChart2TurnOn.sync="isChart2TurnOn"
                @incomeToolObj="incomeTooltipsObj"
              />
            </div>
            <el-row>
              <el-col class="header-flex-xs-center" :span="24" :xs="24">
                <div class="no-mobile">
                  <el-button
                    v-if="compoSite"
                    @click="newComposite"
                    type="primary"
                    icon="el-icon-document"
                    size="medium"
                    plain
                    round
                  >
                    <span>New composite</span>
                  </el-button>
                  <el-button
                    v-if="compoSite.is_stored && isLogged"
                    @click="deleteComposite(compoSite.id)"
                    icon="el-icon-document-remove"
                    type="danger"
                    size="medium"
                    plain
                    round
                  >
                    <span>Delete composite</span>
                  </el-button>
                  <el-button
                    v-if="!compoSite.is_stored"
                    @click="saveComposite(compoSite.id)"
                    icon="el-icon-document-add"
                    type="success"
                    size="medium"
                    plain
                    round
                  >
                    <span>Save composite</span>
                  </el-button>
                  <el-button
                    @click="shareComposite(compoSite.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="medium"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-link
                    v-if="isLogged"
                    download="composite.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-link
                    v-else
                    @click="showDialog = true"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-button
                    v-if="!isLogged"
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="medium"
                    plain
                    round
                  >
                    <span>Login/Register</span>
                  </el-button>
                </div>
                <div class="no-desktop">
                  <el-button
                    v-if="compoSite"
                    @click="newComposite"
                    type="primary"
                    icon="el-icon-document"
                    size="mini"
                    plain
                    round
                  >
                    <span>New</span>
                  </el-button>
                  <el-button
                    v-if="compoSite.is_stored && isLogged"
                    @click="deleteComposite(compoSite.id)"
                    icon="el-icon-document-remove"
                    type="danger"
                    size="mini"
                    plain
                    round
                  >
                    <span>Delete</span>
                  </el-button>
                  <el-button
                    v-if="!compoSite.is_stored"
                    @click="saveComposite(compoSite.id)"
                    icon="el-icon-document-add"
                    type="success"
                    size="mini"
                    plain
                    round
                  >
                    <span>Save</span>
                  </el-button>
                  <el-button
                    @click="shareComposite(compoSite.id)"
                    icon="el-icon-document-add"
                    type="warning"
                    size="mini"
                    plain
                    round
                  >
                    <span>Share</span>
                  </el-button>
                  <el-link
                    download="composite.png"
                    :href="png"
                    icon="el-icon-document-add"
                    :underline="false"
                    type="info"
                    class="custom-download"
                  >
                    <span>Download</span>
                  </el-link>
                  <el-button
                    v-if="!isLogged"
                    @click="showDialog = true"
                    type="primary"
                    icon="el-icon-user"
                    size="mini"
                    plain
                    round
                  >
                    <span>Login</span>
                  </el-button>
                </div>
              </el-col>
              <el-col>
                <textarea
                  ref="targetCompositeUrl"
                  :value="shareLink"
                  style="height:1px;opacity:0"
                ></textarea>
              </el-col>
            </el-row>
            <el-collapse
              v-if="!isUnderConstructionComosite"
              v-model="compositeCollaps.activeLove"
              @change="changeCompositeCollaps"
              style="margin-bottom:20px"
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="collaps-title">About Love</span>
                </template>
                <div v-html="definitionLove" class="tip"></div>
              </el-collapse-item>
            </el-collapse>
            <div v-if="!isUnderConstructionComosite" class="flex-column">
              <el-col :span="24" :xs="24" class="el-col-item">
                <el-card shadow="never" class="min-height-160">
                  <div slot="header" class="clearfix text-align-left">
                    <span>Love: {{ compoSite.connection.love.title }}</span>
                  </div>
                  <div
                    v-html="compoSite.connection.love.tooltip"
                    class="text-align-left"
                  ></div>
                  <div class="bottom clearfix">
                    <el-button
                      v-if="
                        compoSite.tooltip_type !== 3 && isTurnOnFullDescription
                      "
                      type="primary"
                      @click="fullDescript"
                      plain
                    >
                      Full description
                      <i class="el-icon-document"></i>
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </div>
            <el-collapse
              v-if="!isUnderConstructionComosite"
              v-model="compositeCollaps.activeСompatibility"
              @change="changeCompositeCollaps"
              style="margin-bottom:20px"
            >
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="collaps-title">About Сompatibility</span>
                </template>
                <div v-html="definitionСompatibility" class="tip"></div>
              </el-collapse-item>
            </el-collapse>
            <div v-if="!isUnderConstructionComosite" class="flex-column">
              <el-col :span="24" :xs="24" class="el-col-item">
                <el-card shadow="never" class="min-height-160">
                  <div slot="header" class="clearfix text-align-left">
                    <span>
                      Сompatibility:
                      {{ compoSite.connection.type_compatibility.title }}
                    </span>
                  </div>
                  <div
                    v-html="compoSite.connection.type_compatibility.tooltip"
                    class="text-align-left"
                  ></div>
                  <div class="bottom clearfix">
                    <el-button
                      v-if="
                        compoSite.tooltip_type !== 3 && isTurnOnFullDescription
                      "
                      type="primary"
                      @click="fullDescript"
                      plain
                    >
                      Full description
                      <i class="el-icon-document"></i>
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </div>
            <h3 v-if="!isUnderConstructionComosite">Channels:</h3>
            <el-tabs
              v-if="!isUnderConstructionComosite"
              v-model="activeChannels"
              stretch
            >
              <el-tab-pane label="Electromagnetic" name="electromagnetic">
                <el-collapse
                  v-model="compositeCollaps.activeElectro"
                  @change="changeCompositeCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">About Electromagnetic</span>
                    </template>
                    <div v-html="definitionElectro" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div class="flex-column2">
                  <div
                    v-for="(channel, j) in compoSite.connection.channels
                      .channelsElectro"
                    :key="j"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ channel.title }}</span>
                        </div>
                        <div
                          v-html="channel.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Compromise" name="compromise">
                <el-collapse
                  v-model="compositeCollaps.activeCompromise"
                  @change="changeCompositeCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">About Compromise</span>
                    </template>
                    <div v-html="definitionCompromise" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div class="flex-column2">
                  <h4
                    v-if="
                      compoSite.connection.channels.channelsCompromise1.length
                    "
                  >
                    {{ compoSite.first_name }}
                  </h4>
                  <div
                    v-for="(channel, j) in compoSite.connection.channels
                      .channelsCompromise1"
                    :key="j"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ channel.title }}</span>
                        </div>
                        <div
                          v-html="channel.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
                <div class="flex-column2">
                  <h4
                    v-if="
                      compoSite.connection.channels.channelsCompromise2.length
                    "
                  >
                    {{ compoSite.first_name2 }}
                  </h4>
                  <div
                    v-for="(channel, j) in compoSite.connection.channels
                      .channelsCompromise2"
                    :key="j"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ channel.title }}</span>
                        </div>
                        <div
                          v-html="channel.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Educational" name="educational">
                <el-collapse
                  v-model="compositeCollaps.activeEducational"
                  @change="changeCompositeCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">About Educational</span>
                    </template>
                    <div v-html="definitionEducational" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div class="flex-column2">
                  <h4
                    v-if="
                      compoSite.connection.channels.channelsEducational1.length
                    "
                  >
                    {{ compoSite.first_name }}
                  </h4>
                  <div
                    v-for="(channel, j) in compoSite.connection.channels
                      .channelsEducational1"
                    :key="j"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ channel.title }}</span>
                        </div>
                        <div
                          v-html="channel.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
                <div class="flex-column2">
                  <h4
                    v-if="
                      compoSite.connection.channels.channelsEducational2.length
                    "
                  >
                    {{ compoSite.first_name2 }}
                  </h4>
                  <div
                    v-for="(channel, j) in compoSite.connection.channels
                      .channelsEducational2"
                    :key="j"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ channel.title }}</span>
                        </div>
                        <div
                          v-html="channel.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <h3 v-if="!isUnderConstructionComosite">Centers:</h3>
            <el-tabs
              v-if="!isUnderConstructionComosite"
              v-model="activeCenters"
              stretch
            >
              <el-tab-pane label="Companion" name="companion">
                <el-collapse
                  v-model="compositeCollaps.activeCompanion"
                  @change="changeCompositeCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">About Companion centers</span>
                    </template>
                    <div v-html="definitionCompanion" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div class="flex-column2">
                  <div
                    v-for="(center, i) in compoSite.connection.centers
                      .centersDefinedAtBothCharts"
                    :key="i"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ center.title }}</span>
                        </div>
                        <div
                          v-html="center.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Electromagnetic" name="electromagnetic">
                <el-collapse
                  v-model="compositeCollaps.activeElectroChannels"
                  @change="changeCompositeCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">
                        About Electromagnetic centers
                      </span>
                    </template>
                    <div v-html="definitionElectromagnetic" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div class="flex-column2">
                  <div
                    v-for="(center, i) in compoSite.connection.centers
                      .centersDefinedOnlyAtChartsConnection"
                    :key="i"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ center.title }}</span>
                        </div>
                        <div
                          v-html="center.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Dominant" name="dominant">
                <el-collapse
                  v-model="compositeCollaps.activeDominant"
                  @change="changeCompositeCollaps"
                  style="margin-bottom:20px"
                >
                  <el-collapse-item name="1">
                    <template slot="title">
                      <span class="collaps-title">
                        About dominant centers
                      </span>
                    </template>
                    <div v-html="definitionDominant" class="tip"></div>
                  </el-collapse-item>
                </el-collapse>
                <div class="flex-column2">
                  <h4
                    v-if="
                      compoSite.connection.centers.centersDefinedOnlyAtChart1
                        .length
                    "
                  >
                    {{ compoSite.first_name }}
                  </h4>
                  <div
                    v-for="(center, i) in compoSite.connection.centers
                      .centersDefinedOnlyAtChart1"
                    :key="i"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ center.title }}</span>
                        </div>
                        <div
                          v-html="center.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
                <div class="flex-column2">
                  <h4
                    v-if="
                      compoSite.connection.centers.centersDefinedOnlyAtChart2
                        .length
                    "
                  >
                    {{ compoSite.first_name2 }}
                  </h4>
                  <div
                    v-for="(center, i) in compoSite.connection.centers
                      .centersDefinedOnlyAtChart2"
                    :key="i"
                  >
                    <el-col :span="12" :xs="24" class="el-col-item">
                      <el-card shadow="never" class="min-height-160">
                        <div slot="header" class="clearfix text-align-left">
                          <span>{{ center.title }}</span>
                        </div>
                        <div
                          v-html="center.tooltip"
                          class="text-align-left"
                        ></div>
                        <div class="bottom clearfix">
                          <el-button
                            v-if="
                              compoSite.tooltip_type !== 3 &&
                                isTurnOnFullDescription
                            "
                            type="primary"
                            @click="fullDescript"
                            plain
                          >
                            Full description
                            <i class="el-icon-document"></i>
                          </el-button>
                        </div>
                      </el-card>
                    </el-col>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import CompositeForm from "@/components/forms/CompositeForm";
import CompoSite from "@/components/CompoSite";
// import Login from "@/components/Login";
import InfoDialog from "@/components/dialogs/InfoDialog";
import LoginDialog from "@/components/dialogs/LoginDialog";
import RemindDialog from "@/components/dialogs/RemindDialog";
import AfterSuccessRegDialog from "@/components/dialogs/AfterSuccessRegDialog";
import TooltipDialog from "@/components/dialogs/TooltipDialog";
import TooltipUnderConstructionDialog from "@/components/dialogs/TooltipUnderConstructionDialog";
import EmailConfirmedDialog from "@/components/dialogs/EmailConfirmedDialog";
import ErrorDialog from "@/components/dialogs/ErrorDialog";
import OrderDialog from "@/components/dialogs/OrderDialog";

export default {
  name: "PoindOfEntry",
  props: {
    msg: String
  },
  components: {
    CompositeForm,
    CompoSite,
    InfoDialog,
    LoginDialog,
    RemindDialog,
    // Login,
    EmailConfirmedDialog,
    AfterSuccessRegDialog,
    TooltipDialog,
    TooltipUnderConstructionDialog,
    ErrorDialog,
    OrderDialog
  },
  data() {
    return {
      showDialog: false,
      showRemindDialog: false,
      showSuccessDialog: false,
      showTooltipDialog: {},
      showEmailDialog: { show: false },
      showTooltipUnderConstructionDialog: false,
      showErrorDialog: { show: false },
      activeName: "Additional Plan",
      activeCenters: "companion",
      activeChannels: "electromagnetic",
      infoDialog: false,
      closeButtonAttr: false,
      shareLink: "",
      image: "",
      png: "",
      isTurnOnFullDescription: true,
      isChartTurnOn: false,
      isChart2TurnOn: false,
      messageIncome:
        "The mail has been sent successfully. All mails are processed in the order of arrival. On average, it takes from 2 to 4 hours.",
      isFirstTimeComp: true,
      orderDialog: false
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    isEmailConfirmed: {
      get() {
        return this.$store.getters.IS_EMAIL_CONFIRMED;
      },
      set(value) {
        this.$store.commit("IS_EMAIL", value);
      }
    },
    isUnderConstructionComosite: {
      get() {
        return this.$store.getters.IS_UNDER_CONSTRUCTION_COMPOSITE;
      },
      set(value) {
        this.$store.commit("IS_UNDER_CONSTR_COMPOSITE", value);
      }
    },
    isUnderConstructionComositeTooltips: {
      get() {
        return this.$store.getters.IS_UNDER_CONSTRUCTION_COMPOSITE_TOOLTIPS;
      },
      set(value) {
        this.$store.commit("IS_UNDER_CONSTR_COMPOSITE_TOOLTIPS", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    email: {
      get() {
        return this.$store.state.User.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      }
    },
    activeNameTab: {
      get() {
        return this.$store.state.User.activeNameTab;
      },
      set(value) {
        this.$store.commit("SET_ACTIVE_NAME_TAB", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    compositeCollaps() {
      return this.$store.state.CompositeCollaps;
    },
    compoSite() {
      return this.User.chartComposite;
    },
    definitionLove() {
      return this.getMessage("definition_love");
    },
    definitionСompatibility() {
      return this.getMessage("definition_compatibility");
    },
    definitionElectro() {
      return this.getMessage("definition_electro");
    },
    definitionCompromise() {
      return this.getMessage("definition_compromise");
    },
    definitionEducational() {
      return this.getMessage("definition_educational");
    },
    definitionCompanion() {
      return this.getMessage("definition_companion");
    },
    definitionElectromagnetic() {
      return this.getMessage("definition_electromagnetic");
    },
    definitionDominant() {
      return this.getMessage("definition_dominant");
    },
    isTooltipsUnderConstruction: {
      get() {
        return this.$store.getters.IS_TOOLTIPS_UNDER_CONSTRUCTION;
      },
      set(value) {
        this.$store.commit("IS_TOOLTIPS_UNDER_CONSTR", value);
      }
    },
    nameOfReport() {
      const chartBodygraph = JSON.parse(localStorage.getItem("chartBodygraph"));
      return this.User.reportName
        ? this.User.reportName
        : chartBodygraph
        ? chartBodygraph.first_name
        : "";
    },
    emailIncome() {
      return this.User.email ? this.User.email : localStorage.getItem("email");
    }
  },
  mounted() {
    this.initApp();
    this.getSettings();
    this.getProfile();
    this.initReport();
    setTimeout(() => {
      this.getAndStoreUserQueries();
      if (this.compoSite) {
        this.downloadComposite();
        this.matchCompositeChannels();
      }
    }, 100);
    if (localStorage.getItem("isFirstTimeComp")) {
      this.isFirstTimeComp = false;
    }
  },
  methods: {
    ...mapMutations(["SET_COMPOSITE", "IS_EMAIL"]),
    async getSettings() {
      try {
        const result = await Api.getSettings({});
        if (result.success) {
          // console.log(result.messages);
          this.$store.commit("SET_MESSAGES", result.messages);
          // console.log(this.$store.state.Messages);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getProfile() {
      if (this.isLogged) {
        try {
          const result = await Api.getProfile();
          if (result.success) {
            if (result.profile.is_email_confirmed) {
              this.IS_EMAIL(true);
            } else {
              this.IS_EMAIL(false);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    fullDescript() {
      if (this.isLogged) {
        // this.infoDialog = true;
        this.orderDialog = true;
      } else {
        this.scrollToInfoBlock();
        //this.showDialog = true;
      }
    },
    initReport() {
      this.getItemFromLocalStorage("compositeForm", "SET_COMPOSITE_FORM");
      this.getItemFromLocalStorage("compositeCity", "SET_COMPOSITE_CITY_ID");
      this.getItemFromLocalStorage("compositeCity2", "SET_COMPOSITE_CITY2_ID");
      this.getItemFromLocalStorage("compositeCollaps", "SET_COMPOSITE_COLLAPS");
      this.getItemFromLocalStorage("chartComposite", "SET_COMPOSITE");
    },
    async getExit() {
      this.name = "";
      this.token = "life-chart-token";
      this.isLogged = false;
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      // localStorage.removeItem("chartComposite");
      /* try {
        const data = {};
        const result = await Api.logout(data);
        if (result.success) {
          this.name = "";
          this.token = "life-chart-token";
          this.isLogged = false;
          localStorage.removeItem("name");
          localStorage.removeItem("token");
        }
      } catch (e) {
        console.log(e);
      } */
    },
    closeDialog(val) {
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    },
    closeRemindDialog() {
      this.showRemindDialog = false;
    },
    closeSuccessRegDialog(v) {
      this.showSuccessDialog = v;
    },
    closeInfoDialog() {
      this.infoDialog = false;
    },
    newComposite() {
      localStorage.removeItem("chartComposite");
      this.SET_COMPOSITE(null);
      const widgethead = document.getElementById("app");
      widgethead.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    async saveComposite(id) {
      if (this.isLogged) {
        try {
          const reportParams = {
            report_id: id
          };
          const result = await Api.storeComposite(reportParams);
          if (result.success) {
            // console.log(result.report);
            this.compoSite.is_stored = true;
            // Save to localStorage chartComposite, Tooltips, Report Id & Stored
            localStorage.setItem(
              "chartComposite",
              JSON.stringify(this.compoSite)
            );
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.showDialog = true;
      }
    },
    async deleteComposite(id) {
      try {
        const reportParams = {
          report_id: id
        };
        const result = await Api.unstoreComposite(reportParams);
        if (result.success) {
          // console.log(result.report);
          this.compoSite.is_stored = false;
          localStorage.setItem(
            "chartComposite",
            JSON.stringify(this.compoSite)
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    async shareComposite(id, isUserProduct) {
      if (this.isLogged) {
        try {
          this.shareLink = "";
          const result = isUserProduct
            ? await Api.shareUserProduct({ user_product_id: id })
            : await Api.shareCompositeReport({ report_id: id });
          if (result.success) {
            this.shareLink = result.share_link;
            // console.log(this.shareLink);
            setTimeout(() => {
              this.$refs.targetCompositeUrl.select();
              document.execCommand("copy");
            }, 100);
            this.$message({
              message: "Link to share copied",
              type: "success"
            });
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.showDialog = true;
      }
    },
    downloadComposite() {
      const svgElement = document.getElementById("svgComposite1582");
      let { width, height } = svgElement.getBBox();
      // true for deep clone
      let clonedSvgElement = svgElement.cloneNode(true);
      let outerHTML = clonedSvgElement.outerHTML,
        blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });
      let URL = window.URL || window.webkitURL || window;
      let blobURL = URL.createObjectURL(blob);
      let image = new Image();
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = Math.round(width);
        canvas.height = Math.round(height);
        let context = canvas.getContext("2d");
        // draw image in canvas starting left-0 , top - 0
        context.drawImage(image, 0, 0, Math.round(width), Math.round(height));
        this.png = canvas.toDataURL();
        // console.log("png: ", this.png);
      };
      image.src = blobURL;
    },
    incomeTooltipsObj(obj) {
      // console.log(obj);
      if (this.isUnderConstructionComositeTooltips) {
        // this.showTooltipUnderConstructionDialog = true;
        return;
      } else {
        if (this.isLogged) {
          this.showTooltipDialog = {
            show: true,
            tooltip: obj
          };
        } /* else if (this.isLogged && !this.isEmailConfirmed) {
          this.showEmailDialog = {
            show: true,
            message: "You need to confirm your email"
          };
        } */ else {
          this.showDialog = true;
        }
      }
    },
    closeEmailDialog() {
      this.showEmailDialog = { show: false };
    },
    closeTooltipDialog(obj) {
      this.showTooltipDialog = obj;
    },
    closeTooltipUnderDialog() {
      this.showTooltipUnderConstructionDialog = false;
    },
    closeErrorDialog(obj) {
      this.showErrorDialog = obj;
    },
    changeCompositeCollaps() {
      this.setItemToLocalStorage(
        "compositeCollaps",
        JSON.stringify(this.compositeCollaps)
      );
    },
    openOnlyReport(n) {
      this.setItemToLocalStorage("isFirstTimeComp", false);
      this.isFirstTimeComp = false;
      if (n === 1) {
        this.isChartTurnOn = !this.isChartTurnOn;
        if (this.isChart2TurnOn) this.isChart2TurnOn = false;
      } else {
        this.isChart2TurnOn = !this.isChart2TurnOn;
        if (this.isChartTurnOn) this.isChartTurnOn = false;
      }
    },
    matchCompositeChannelsAnddownloadComposite(v) {
      if (v) {
        this.matchCompositeChannels();
        setTimeout(() => {
          if (this.compoSite) this.downloadComposite();
        }, 100);
      }
    },
    getAndStoreUserQueries() {
      // Open Order Dialog if query is true
      if (this.$route.query.order_dialog) {
        this.openOrderDialog();
      }
    },
    openOrderDialog() {
      this.orderDialog = true;
    },
    closeOrderDialog() {
      this.orderDialog = false;
    }
  }
};
</script>
<style scoped>
.h3 {
  font-size: 16px;
  font-weight: 500;
}
.city {
  display: flex;
  align-items: center;
}
.city-right-side {
  display: flex;
  margin-bottom: 10px;
}
.city-title {
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.country-title {
  font-size: 12px;
  color: #afafaf;
  padding-left: 7px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-align-left {
  text-align: left;
}
.flex {
  display: flex;
}
.padding-lt-0 {
  padding-left: 0 !important;
}
.padding-rt-0 {
  padding-right: 0 !important;
}
.width-100 {
  width: 100%;
}
.justify-end {
  justify-content: end;
}
.report-form {
  min-width: 500px;
  max-width: 500px;
}
.report {
  min-width: 500px !important;
  max-width: 920px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-column {
  display: flex;
}
.el-col-item {
  margin-bottom: 20px;
}
.flex-column .el-col-item,
.flex-column2 .el-col-item {
  align-self: stretch;
}
.flex-column .el-col-item .el-card,
.flex-column2 .el-col-item .el-card {
  height: 100%;
}
.tip {
  padding: 8px 16px;
  background-color: #f0f0fd;
  border-radius: 4px;
  border-left: 5px solid #bcbeff;
  margin: 15px 10px;
  text-align: left;
  font-size: 16px;
  color: #4c4e86;
  line-height: 1.5em;
}
.width-70 {
  width: 70%;
}
.login-wrapper-desk {
  margin: 0 70px;
}
.users-cards,
.users-cards2 {
  display: inline-block;
  vertical-align: baseline;
  color: #555555;
  background: #fafafa;
  border: 1px solid;
  border-color: #e0e0e0;
  padding: 12px 20px;
  border-radius: 20px;
  margin: 0 !important;
  font-size: 14px;
  font-family: Arial;
  min-width: 150px;
}
.users-cards:hover,
.users-cards2:hover,
.users-cards-active {
  background: #4d4f7e;
  border-color: #4d4f7e;
  color: #fff !important;
}
.users-cards {
  border-top-right-radius: initial;
  border-bottom-right-radius: initial;
  /* clip-path: polygon(0 60%, 100% 3%, 100% 100%, 0 75%); */
}
.users-cards2 {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-left-width: 0;
}
.pulse-item {
  animation: shadow 1.5s infinite linear;
}
@keyframes shadow {
  0% {
    border-color: #e0e0e0;
  }
  25% {
    border-color: #bcbeff;
  }
  50% {
    border-color: #4d4f7e;
  }
  75% {
    border-color: #bcbeff;
  }
  100% {
    border-color: #e0e0e0;
  }
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .flex-column,
  .flex-column2 {
    flex-direction: column;
  }
  .flex-column .el-col-item,
  .flex-column2 .el-col-item {
    align-self: start;
  }
  .report-form,
  .report {
    min-width: initial;
  }
  .width-100-m {
    width: 100%;
  }
  .login-wrapper-desk {
    margin: 0;
  }
  .report-form {
    min-width: 320px;
    max-width: 430px;
  }
  .report {
    min-width: 320px !important;
    max-width: 430px !important;
  }
}
</style>
