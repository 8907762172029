import { HTTP } from "@/plugin/axios";
import Store from "@/store/index";
const options = Store.state.http_options;

export default class Api {
  static login(data) {
    return HTTP.post("auth/login", data, options).then(
      response => response.data
    );
  }
  static register(data) {
    return HTTP.post("auth/signup", data, options).then(
      response => response.data
    );
  }
  static logout(data) {
    return HTTP.post("auth/logout", data, options).then(
      response => response.data
    );
  }
  static sendCredentials(data) {
    return HTTP.post("auth/send-credentials", data, options).then(
      response => response.data
    );
  }
  static searchSities(data) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("cities/get-cities" + queries, options).then(
      response => response.data
    );
  }
  static createReport(data) {
    return HTTP.post("raves/create-rave-report", data, options).then(
      response => response.data
    );
  }
  static createTransit(data) {
    return HTTP.post("transits/create-transit-report", data, options).then(
      response => response.data
    );
  }
  static createInfliction(data) {
    return HTTP.post(
      "inflictions/create-infliction-report",
      data,
      options
    ).then(response => response.data);
  }
  static sendApplayForChart(data) {
    return HTTP.post("auth/apply-for-chart-description", data, options).then(
      response => response.data
    );
  }
  static getProfile(data) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("auth/get-profile" + queries, options).then(
      response => response.data
    );
  }
  static getConfirmEmail(data) {
    const queries = data ? "?email_hash=" + data : "";
    return HTTP.get("auth/confirm-email" + queries, options).then(
      response => response.data
    );
  }
  static sendSignupEmail() {
    return HTTP.post("auth/send-signup-email", {}, options).then(
      response => response.data
    );
  }
  static getReport(id) {
    const queries = id ? "?report_id=" + id : "";
    return HTTP.get("raves/get-rave-report" + queries, options).then(
      response => response.data
    );
  }
  static saveReport(data) {
    return HTTP.post("raves/store-rave-report", data, options).then(
      response => response.data
    );
  }
  static deleteReport(data) {
    return HTTP.post("raves/unstore-rave-report", data, options).then(
      response => response.data
    );
  }
  static shareRaveReport(data) {
    return HTTP.post("raves/share-rave-report", data, options).then(
      response => response.data
    );
  }
  static shareTransitReport(data) {
    return HTTP.post("transits/share-transit-report", data, options).then(
      response => response.data
    );
  }
  static shareInflictionReport(data) {
    return HTTP.post("inflictions/share-infliction-report", data, options).then(
      response => response.data
    );
  }
  static getSharedTransitReport(hash) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get(
      "transits/get-shared-transit-report" + queries,
      options
    ).then(response => response.data);
  }
  static getSharedInflictionReport(hash) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get(
      "inflictions/get-shared-infliction-report" + queries,
      options
    ).then(response => response.data);
  }
  static getSharedRaveReport(hash) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get("raves/get-shared-rave-report" + queries, options).then(
      response => response.data
    );
  }
  static shareUserProduct(data) {
    return HTTP.post("user-products/share-user-product", data, options).then(
      response => response.data
    );
  }
  static getSettings(data) {
    const queries = data ? "?q=" + data : "";
    return HTTP.get("settings/get-settings" + queries, options).then(
      response => response.data
    );
  }
  static createComposite(data) {
    return HTTP.post("composites/create-composite-report", data, options).then(
      response => response.data
    );
  }
  static shareCompositeReport(data) {
    return HTTP.post("composites/share-composite-report", data, options).then(
      response => response.data
    );
  }
  static getSharedCompositeReport(hash) {
    const queries = hash ? "?report_hash=" + hash : "";
    return HTTP.get(
      "composites/get-shared-composite-report" + queries,
      options
    ).then(response => response.data);
  }
  static storeComposite(data) {
    return HTTP.post("composites/store-composite-report", data, options).then(
      response => response.data
    );
  }
  static unstoreComposite(data) {
    return HTTP.post("composites/unstore-composite-report", data, options).then(
      response => response.data
    );
  }
  static getCompositeReport(id) {
    const queries = id ? "?report_id=" + id : "";
    return HTTP.get("composites/get-composite-report" + queries, options).then(
      response => response.data
    );
  }
  static createOrder(data) {
    return HTTP.post("user-products/create-order", data, options).then(
      response => response.data
    );
  }
  static createCompositeOrder(data) {
    return HTTP.post(
      "user-products/create-composite-order",
      data,
      options
    ).then(response => response.data);
  }
  static updateOrder(data) {
    return HTTP.post("user-products/update-order", data, options).then(
      response => response.data
    );
  }
}
