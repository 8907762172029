<template>
  <el-dialog
    title="Video Example"
    :visible.sync="videoExampleDialogIncome"
    :before-close="closeDialog"
    append-to-body
    width="90%"
    top="3vh"
    class="common-dialog"
  >
    <el-row
      type="flex-row"
      justify="center"
      :gutter="20"
      append-to-body
      class="xs-flex-direction-column"
    >
      <div style="margin-bottom:15px;text-align:center">
        <video ref="video" controls autoplay width="100%">
          <source
            src="https://life-chart.art/wp-content/uploads/video/videoexmpl.mp4"
            type="video/mp4"
          />
          Download the
          <a
            href="https://life-chart.art/wp-content/uploads/video/videoexmpl.mp4"
          >
            MP4
          </a>
          video.
        </video>
      </div>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Close</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "VideoExampleDialog",
  props: {
    videoExampleDialogIncome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
      this.$emit("closeVideoExampleDialog", false);
    }
  }
};
</script>
